import React, { useState } from "react";
import QRCode from "react-qr-code";
import { saveSvgAsPng } from "save-svg-as-png";

import "./App.css";

function App() {
    const [content, setContent] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("#ffffff");

    const downloadQRCode = () => {
        saveSvgAsPng(document.querySelector("svg"), "qrcode.png", {
            scale: 5,
        });
    };

    return (
        <div
            className="App"
            style={{
                backgroundColor: backgroundColor,
            }}
        >
            <div>
                <h1 className="App-title">QR Code Generator</h1>
                <div className="App-form-elem">
                    <label htmlFor="content">Content</label>
                    <input
                        type="text"
                        placeholder="Enter Content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                </div>
                <div className="App-form-elem">
                    <label htmlFor="backgroundColor">Background Color</label>
                    <input
                        type="color"
                        value={backgroundColor}
                        onChange={(e) => setBackgroundColor(e.target.value)}
                    />
                </div>
                <div style={{ margin: "20px" }}>
                    <QRCode bgColor={backgroundColor} value={content} />
                </div>
                <div>
                    <button onClick={downloadQRCode}>Download QR Code</button>
                </div>
            </div>
        </div>
    );
}

export default App;
